import Component from '@glimmer/component';
import { action } from '@ember/object';
import $ from 'jquery';

export default class MessageVideoRequest extends Component {
	@action
	uploadVideo() {
		// eslint-disable-next-line ember/no-jquery
		$('#dz-uploader-videos').click();
	}
}
