/** @format */

import Mixin from '@ember/object/mixin';
import { resolve, reject } from 'rsvp';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import Errors from '../../../constants/errors';
import { format } from 'libphonenumber-js';
import { createErrorIfFileTooBig } from '../../../helpers/validate-file-size';

export default Mixin.create({
	currentUser: service(),
	notifications: service(),

	formattedBusinessNumber: computed('model.businessNumber', function () {
		let phoneNumberOriginal = this.get('model.businessNumber');
		if (phoneNumberOriginal) {
			return format(phoneNumberOriginal, 'International');
		}
	}),

	maxUploadFileMB: computed(
		'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
		function () {
			return this.get(
				'currentUser.user.firm.firmSettings.firmSettings.max_file_size',
			);
		},
	),

	formattedCellNumber: computed('model.cellPhone', function () {
		let phoneNumberOriginal = this.get('model.cellPhone');
		if (phoneNumberOriginal) {
			return format(phoneNumberOriginal, 'International');
		}
	}),

	formattedEmailAddress: computed(
		'model.{emailAddress,contactEmailAddress}',
		function () {
			let contactEmailAddress = this.get('model.contactEmailAddress');
			return contactEmailAddress
				? contactEmailAddress
				: this.get('model.emailAddress');
		},
	),

	profileUpdateObserver: observer('shouldCrop', 'updatedPhoto', function () {
		if (this.shouldCrop && this.updatedPhoto) {
			this.send('saveBio');

			this.set('shouldCrop', false);
			this.set('updatedPhoto', false);
		}
	}),

	isEditingAccountInfo: false,
	isEditingBio: false,
	shouldCrop: false,
	updatedPhoto: false,

	saveModel() {
		return this.model
			.save()
			.then(() => {
				this.set('errors', null);
				return resolve();
			})
			.catch((response) => {
				this.set('errors', Errors.mapResponseErrors(response));
				return reject();
			});
	},

	actions: {
		cancelEditing() {
			if (this.isEditingAccountInfo) {
				this.send('cancelEditAccountInformation');
			}
			if (this.isEditingBio) {
				this.send('cancelEditBio');
			}
			this.set('passwordSuccessMessage', null);
		},

		cancelEditAccountInformation() {
			if (this.oldFirstName) {
				this.set('model.firstName', this.oldFirstName);
				this.set('model.lastName', this.oldLastName);
				this.set('model.emailAddress', this.oldEmailAddress);
				this.set('model.cellPhone', this.oldCellPhone);
			}

			this.set('isEditingAccountInfo', false);
			this.set('showAccountError', false);
		},

		cancelEditBio() {
			if (this.oldBio) {
				this.set('model.bio', this.oldBio);
				//The business number is displayed to the client
				this.set('model.businessNumber', this.oldBusinessNumber);
			}

			this.set('isEditingBio', false);
			this.set('showBioError', false);
		},

		editAccountInformation() {
			const firstName = this.get('model.firstName');
			const lastName = this.get('model.lastName');
			const emailAddress = this.get('model.emailAddress');
			const cellPhone = this.get('model.cellPhone');

			this.set('oldFirstName', firstName);
			this.set('oldLastName', lastName);
			this.set('oldEmailAddress', emailAddress);
			this.set('oldCellPhone', cellPhone);

			this.set('isEditingAccountInfo', true);
		},

		editBio() {
			this.set('oldBio', this.get('model.bio'));
			this.set('oldBusinessNumber', this.get('model.businessNumber'));

			this.set('isEditingBio', true);
		},

		saveAccountInformation() {
			const isValid = this.get('model.validations.isValid');
			const accountPhoneNumberErrorsLength = this.get(
				'accountPhoneNumberErrors.length',
			);

			if (!isValid || accountPhoneNumberErrorsLength >= 1) {
				this.set('showAccountError', true);
				return reject();
			}

			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},

		saveBio() {
			const isValid = this.get('model.validations.isValid');
			const phoneNumberErrorsLength = this.get('phoneNumberErrors.length');

			if (this.imgToCrop && !this.shouldCrop) {
				this.set('shouldCrop', true);
				return;
			}

			if (!isValid || phoneNumberErrorsLength >= 1) {
				this.set('showBioError', true);
				return reject();
			}
			return this.saveModel()
				.then(() => {
					this.set('isEditingBio', false);
					this.set('showBioError', false);
				})
				.catch(() => {
					this.set('showBioError', true);
				});
		},

		showFilePicker() {
			document.querySelector('#file_uploader').click();
		},

		toggleWantsDailyDigest(value) {
			this.set('model.wantsDailyDigest', value);
			return this.saveModel()
				.then(() => {
					this.set('isEditingAccountInfo', false);
					this.set('showAccountError', false);
				})
				.catch(() => {
					this.set('showAccountError', true);
				});
		},

		uploadFile(e) {
			const file = e.target.files[0];
			const reader = new FileReader();
			const _this = this;

			const errorMsg = createErrorIfFileTooBig([
				file.size,
				this.maxUploadFileMB,
				this.notifications,
			]);
			if (errorMsg != null) {
				return;
			}

			reader.readAsDataURL(file);
			reader.onloadend = function () {
				const base64data = reader.result;
				_this.set('imgToCrop', base64data);
			};
		},

		updatePasswordPressed() {
			const isValid = this.get('validations.isValid');
			if (!isValid) {
				this.set('showPasswordError', true);
				return reject();
			} else {
				this.set('model.password', this.password);
				return this.saveModel()
					.then(() => {
						this.set(
							'passwordSuccessMessage',
							'Password changed successfully!',
						);
						this.set('showPasswordError', false);
					})
					.catch(() => {
						this.set('passwordSuccessMessage', null);
						this.set('showPasswordError', true);
						this.set('model.password', null);
					});
			}
		},

		uploadImage(img) {
			this.send('updateProfilePhoto', img);

			this.set('updatedPhoto', true);
		},

		handleAccountPhoneInputError(message) {
			if (message) {
				this.set('accountPhoneNumberErrors', [message]);
			} else {
				this.set('accountPhoneNumberErrors', []);
			}
		},

		handleBioPhoneInputError(message) {
			if (message) {
				this.set('bioPhoneNumberErrors', [message]);
			} else {
				this.set('bioPhoneNumberErrors', []);
			}
		},
	},
});
