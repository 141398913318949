/* eslint-disable ember/require-tagless-components */
import Component from '@ember/component';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { stripHtml } from 'string-strip-html';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
	// Tracked Definitions
	content: null, //!!! Doesn't seem to update the content back upwards
	messageTemplateType: null,
	allowMessageTemplates: false,
	messageTemplateUsageType: 'message',
	actionInProgress: false,
	// we need `showPlaceholderMessages` so that we can show/hide them based on a variable
	// that the regenerate response logic doesn't depend on, as we don't want to show them
	// in the "regenerate" scenario
	showPlaceholderMessages: false,
	shouldShowComponents: false,
	editorHasContent: false,
	scheduledMessagingDisabled: computed.and(
		'currentUser.isPortalCustomer',
		'isBulkAction',
	),
	scheduledMessageTooltip: computed(
		'currentUser.isPortalCustomer',
		'isBulkAction',
		function () {
			if (this.currentUser.isPortalCustomer && this.isBulkAction) {
				return 'Scheduling bulk messages are disabled for Portal by Case Status users. Upgrade to use this feature.';
			} else {
				return 'Schedule Message';
			}
		},
	),
	messageTemplatesAllowed: computed(
		'allowMessageTemplates',
		'currentUser.user',
		'permissions.user.settings_message_templates',
		function () {
			return (
				this.allowMessageTemplates &&
				this.currentUser.user &&
				this.permissions?.user?.settings_message_templates
			);
		},
	),
	recommendedResponsesAllowed: computed(
		'permissions.case.recommended_responses_feature',
		function () {
			return (
				this.enableAiBanner &&
				this.permissions?.case?.recommended_responses_feature &&
				this.permissions?.case?.recommended_responses_feature
			);
		},
	),

	showAiRecommendations: false,
	fetchedResponses: null,
	recommendedResponseEligibleMessage: null,

	isBold: false,
	isItalic: false,
	isUnderline: false,
	showLanguageOptions: false,
	showSchedulingModal: false,

	// Services
	currentUser: service(),
	permissions: service(),
	notifications: service(),
	company: service(),
	pubnub: service('pubnub-service'),

	init() {
		this._super(...arguments);

		// Set showAiRecommendations based on recommendedResponseEligibleMessage.recommendedResponseRequested
		const recommendedResponseEligibleMessage = this.get(
			'recommendedResponseEligibleMessage',
		);
		if (
			recommendedResponseEligibleMessage &&
			recommendedResponseEligibleMessage.recommendedResponseRequested
		) {
			this.set('showAiRecommendations', true);
		} else {
			this.set('showAiRecommendations', false);
		}
		this.pubnub.newRecommendedResponseCallbacks.push((...args) => {
			this.handleIncomingRecommendedResponse(...args);
		});
	},

	// Lifecycle Events
	didRender(...args) {
		this._super(...args);
		//* Must use didRender so we know the required div with the "tiptap-element" class is rendered and the editor can successfully initialize and attach to it.

		//* Using this.elementId to ensure the targeted class for the Editor to initialize on is unique to prevent cross contamination when multiple are rendered in view

		if (!this.Editor) {
			//* Prevent duplicate initializations during rerenders
			const targetElement = `#${this.elementId}-tiptap-element`;

			const content = this.content;
			const placeholder = this.placeholder;
			const setContent = (c) => this.setContent(c);
			this.set('targetElement', targetElement);
			this.set('messageCaseLanguage', this.caseLanguage);
			this.set(
				'messageTargetLanguage',
				// Client Language dropdown label work around
				// is target language display present and English?
				this.targetLanguage?.display && this.targetLanguage.code == 'en'
					? // set it to blank in order for label to display (clientLanguageLabel to take over) or let target language display
					  ' '
					: this.targetLanguage,
			);
			// work around to display label text only if English is default select
			this.set(
				'clientLanguageLabel',
				this.targetLanguage && this.targetLanguage.code == 'en'
					? 'Client Language'
					: ' ',
			);
			this.customContent = '';
			this.Editor = new Editor({
				element: document.querySelector(targetElement),
				// https://prosemirror.net/docs/ref/#top.intro
				extensions: [
					this.fromChecklist
						? StarterKit.configure({
								paragraph: { HTMLAttributes: { class: 'display-inline' } },
						  })
						: StarterKit,
					Underline,
					Bold,
					Italic,
					Link.configure({
						autoLink: true,
					}),
					Placeholder.configure({
						placeholder: placeholder,
						emptyEditorClass: 'is-editor-empty',
					}),
					CharacterCount, //TODO for implementing character limits as we should, not only to save space but also to try and limit any XSS vulnerabilities we may be unaware of
				],
				content,
				// Arrow functions do not have their own this context; instead, this is lexically inherited from
				// the outer function where the arrow function is defined.

				onUpdate: () => {
					//* This plus the contentObserver allow for two-way updating on the content value

					const docContent = document
						.querySelector(targetElement)
						.querySelector('div').innerHTML;
					const scrubbedContent = docContent;
					// Custom content could be paste clipboard that was stripped of html
					if (this.customContent) {
						setContent(this.customContent);
						this.customContent = '';
					} else {
						setContent(scrubbedContent);
					}
					this.noUpdate = false;
				},
			});
		}
	},

	disableTranslate: computed(
		'messageTargetLanguage.language',
		'messageCaseLanguage.language',
		function () {
			return (
				this?.messageTargetLanguage?.language &&
				this?.messageCaseLanguage?.language &&
				this?.messageTargetLanguage?.language ==
					this?.messageCaseLanguage?.language
			);
		},
	),
	handleIncomingRecommendedResponse(newContent) {
		this.set('content', newContent);
	},
	formattingMesssage() {
		return this.notifications.error(
			'We have removed your formatting to ensure message content is properly sent within Case Status.',
			{
				canClose: true,
				autoClear: true,
				clearDuration: 5000,
			},
		);
	},
	recommendedResponseIsActive: computed(
		'editorHasContent',
		'permissions.case.recommended_response_feature_case_quota_exceeded',
		function () {
			return (
				this.editorHasContent &&
				!this.permissions.case.recommended_response_feature_case_quota_exceeded
			);
		},
	),
	setContent(content) {
		const plainTextContent = this.Editor.state.doc.textContent;

		if (!`${plainTextContent}`.trim()) {
			content = null;
			this.set('shouldShowComponents', false);
			this.set('editorHasContent', false);
		} else {
			this.set('editorHasContent', true);
		}

		this.set('content', content);

		if (this.resetTranslation && typeof this.resetTranslation == 'function') {
			this.resetTranslation();
		}
	},

	// Observers
	contentObserver: observer('content', function () {
		//* When an external source attempts to set the content from that of a template, we must make sure that the editor's content reflects that
		//* This plus the onUpdate event handler on the Editor allow for two-way updating on the content value
		if (
			this.content !==
			document.querySelector(this.targetElement).querySelector('div').innerHTML
		) {
			//* if they are the same then don't update the Editor's content value to prevent causing an infinite loop
			this.Editor.commands.setContent(this.content);
		}
	}),

	shortcode: service(),
	shortcodeOptions: computed(
		'shortcode.{options,conditionalOptions}',
		'specificShortcodeOptions',
		function () {
			const options = this?.shortcode?.options || [];
			const specifics = this?.specificShortcodeOptions || [];

			const filterFunc = (o) => {
				return !this.shortcode.conditionalOptions.includes(o);
			};

			if (specifics && specifics.length) {
				//* Using Sets to create filter out duplicates
				return new Set([...options, ...specifics].filter(filterFunc));
			} else return new Set([...options].filter(filterFunc));
		},
	),

	shortcodeConditionalOptions: computed(
		'shortcode.conditionalOptions',
		function () {
			const conditionalOptions = this.shortcode.conditionalOptions;
			return new Set([...conditionalOptions]);
		},
	),

	// Actions
	actions: {
		onRecommendedResponseSelect(recommendedResponse) {
			// Set locally
			this.set('recommendedResponseUsed', recommendedResponse);

			// Handle visibility of up/down vote buttons on ai-regenerate-response-banner
			if (!recommendedResponse) {
				this.set('shouldShowComponents', false);
			} else {
				this.set('shouldShowComponents', true);
				this.set('editorHasContent', true);
			}

			// Pass upwards
			this.handleRecommendedResponseUsed(recommendedResponse);
		},
		updateActionInProgress(value) {
			this.set('actionInProgress', value);
		},
		updateShowPlaceholderMessages(value) {
			this.set('showPlaceholderMessages', value);
		},
		setInnerContent(content) {
			this.set('content', content);
		},
		getInnerContent() {
			// plaintext content currently in tiptap editor
			return this.Editor.state.doc.textContent;
		},
		toggleBold() {
			this.Editor.chain().focus().toggleBold().run();
			this.toggleProperty('isBold');
		},

		toggleItalic() {
			this.Editor.chain().focus().toggleItalic().run();
			this.toggleProperty('isItalic');
		},

		toggleUnderline() {
			this.Editor.chain().focus().toggleUnderline().run();
			this.toggleProperty('isUnderline');
		},

		toggleLink() {
			const { view, state } = this.Editor;
			const { from, to } = view.state.selection;
			const focusedContent = state.doc.textBetween(from, to, '');

			if (!focusedContent.trim()) {
				return this.notifications.error(
					'You must select text to apply the link to.',
					{
						canClose: true,
						autoClear: true,
						clearDuration: 5000,
					},
				);
			}

			const url = window.prompt('Enter the link URL', 'http://');
			let formattedUrl;
			if (url) {
				formattedUrl = url;
				const isHttp = formattedUrl.indexOf('http:');
				const isHttps = formattedUrl.indexOf('https:');
				if (isHttp === -1 && isHttps === -1) {
					// Defaulting to http because non ssl sites don't redirect from https to http but most ssl sites do redirect from http to https
					formattedUrl = `http://${formattedUrl}`;
				} else if (isHttps > 0) {
					formattedUrl = formattedUrl.slice(0, isHttps);
				} else if (isHttp > 0) {
					formattedUrl = formattedUrl.slice(0, isHttp);
				}

				const formattedContent = focusedContent ? focusedContent : formattedUrl;

				//TODO: Could add link validation that on bad links doesn't insert the link and gives the user a notification on such.
				// A simple HEAD request at first, 200 OK passes, if fails, or responds with '405 Method Not Allowed' fallback and try a GET request.
				// This will minimize bandwidth as much as possible.
				this.Editor.chain()
					.focus()
					.setLink({ href: formattedUrl, target: '_blank' })
					.insertContent(formattedContent)
					.setTextSelection({ from: to + 1, to: to + 1 })
					.unsetMark('link')
					.run();
			} else {
				return;
			}
		},

		toggleMessageTemplates() {
			if (
				document
					.getElementById('modal-add-message-template')
					.classList.contains('hidden')
			) {
				document
					.getElementById('modal-add-message-template')
					.classList.remove('hidden');
			} else {
				document
					.getElementById('modal-add-message-template')
					.classList.add('hidden');
				this.set('messageTemplateFilterString', null);
			}
		},

		selectMessageTemplate(template) {
			//* Destructure the incoming template param
			const { content, templateType, id } = template;

			this.Editor.commands.setContent(content);

			//* setContent command doesn't seem to trigger the onUpdate event, so we must set the tracked content manually
			this.set('content', content);
			this.set('messageTemplateType', templateType);
			this.set('newMessageTemplateId', id);
			this.set('newMessageFile', template.get('fileAttachment'));

			document
				.getElementById('modal-add-message-template')
				.classList.add('hidden');

			if (this.templateCallback && typeof this.templateCallback == 'function') {
				this.templateCallback(template);
			}
		},

		addShortcode(shortcode) {
			const shortcodeContent = shortcode.includes('_link')
				? `<a target="_blank" href="{{${shortcode}}}">{{${shortcode}}}</a> `
				: `{{${shortcode}}}`;
			this.Editor.chain().focus().insertContent(shortcodeContent).run();

			const docContent = document
				.querySelector(this.targetElement)
				.querySelector('div').innerHTML;
			this.setContent(docContent);
		},

		toggleShowLanguageOptions() {
			this.toggleProperty('showLanguageOptions');
		},

		selectCaseLanguage(value) {
			this.set('messageCaseLanguage', value);
			if (this.resetTranslation && typeof this.resetTranslation == 'function') {
				this.resetTranslation();
			}
		},

		selectTargetLanguage(value) {
			this.set('targetLanguage', value);
			this.set('messageTargetLanguage', value);
			this.set('targetLanguage', value);
			this.set('clientLanguageLabel', ' ');
			if (this.resetTranslation && typeof this.resetTranslation == 'function') {
				this.resetTranslation();
			}
		},

		clearTargetLanguage() {
			this.set('messageTargetLanguage', null);
			this.set('targetLanguage', null);
			if (this.resetTranslation && typeof this.resetTranslation == 'function') {
				this.resetTranslation();
			}
		},

		saveCase() {
			return this.theCase?.save().then(() => {
				this.set('showLanguageOptions', false);
			});
		},

		toggleMessageScheduling() {
			if (!this.scheduledMessagingDisabled) {
				this.toggleProperty('showSchedulingModal');
			}
		},

		toggleAiResponses() {
			this.toggleProperty('showAiRecommendations');
		},

		handleResponsesFetched(responses) {
			this.set('fetchedResponses', responses);
		},
	},
});
