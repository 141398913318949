/** @format */

import Controller from '@ember/controller';
import Validations from '../../../../constants/validations';
import { buildValidations } from 'ember-cp-validations';
import SettingsAccountMixin from '../../../../mixins/controller/settings/account';

export default Controller.extend(
	buildValidations(Validations.Password),
	SettingsAccountMixin,
);
