/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { sort, uniq, reads } from '@ember/object/computed';
import moment from 'moment';
import { next } from '@ember/runloop';
import { action } from '@ember/object';

@classic
export default class MobileClientMessagingTabComponent extends Component {
	@service('pubnub-service') pubnub;
	@service store;
	@service ajax;
	@service global;
	@service currentUser;

	//* For the scroll to fetch feature
	@service inViewport;
	@tracked loaderInViewPort = false;
	@tracked isLoading = true;
	get showLoader() {
		return this.isLoading && this.loaderInViewPort;
	}

	@reads('model.id') caseId;

	@tracked caseMessages = A([]);
	messagesSort = ['sentAt:desc'];
	@sort('caseMessages', 'messagesSort') sortedMessages;
	@uniq('sortedMessages') uniqMessages;

	get messages() {
		return this.uniqMessages.filter((message) => {
			return (
				!message.get('scheduledMessageSendDate') ||
				moment(message.get('scheduledMessageSendDate')).isBefore(moment())
			);
		});
	}

	@tracked meta = {
		total_pages: 0,
	};
	@tracked page = 1;
	@tracked size = 5;

	get moreMessagesToLoad() {
		return this.meta.total_pages > this.page;
	}

	//* For sending a message
	@tracked isWritingMessage = false;
	@tracked newMessage = '';

	didEnterViewPort() {
		if (this.messages?.length) {
			this.loaderInViewPort = true;
			if (this.page || this.meta.total_pages) {
				this.fetchMoreMessages();
			}
		}
	}
	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreCaseMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreMessages();
			}
		}, 500);
	}

	didExitViewPort() {
		this.loaderInViewPort = false;
	}

	@action
	initMessages() {
		//* Set loading state to true
		this.isLoading = true;
		this.isLoadingNew = true;

		//* Clear the current list of case messages
		this.caseMessages.clear();

		//* Reset page number to 1
		this.page = 1;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property
		this.queryParams.page.size = this.size;

		//* Set page number
		this.queryParams.page.number = this.page;

		//* Set the filter object's search property to equal the current searchTerm property
		this.queryParams.filter['search'] = this.searchTerm;

		//* Make query request
		return this.store
			.query('message', { caseId: this.caseId, params: this.queryParams })
			.then((res) => {
				//* Update meta data
				this.meta = res.meta;

				//* Clear old messages
				this.caseMessages.clear();

				//* Push in new messages
				this.caseMessages.pushObjects(res.toArray());

				//* Set isLoadingNew to false
				this.isLoadingNew = false;

				this.set('errorFetchingMessages', false);
			})
			.catch(() => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
					this.scrollToNewest();
				});
			});
	}

	fetchMoreMessages() {
		if (!this.meta.total_pages || this.page + 1 > this.meta.total_pages) return;
		//* Set loading state to true
		this.isLoading = true;

		//* Structure a queryParams Object to be passed to our query
		this.queryParams = {
			filter: {},
			page: {},
		};

		//* Set the page Object's size property
		this.queryParams.page.size = this.size;

		//* Increment the page number
		this.page = this.page + 1;

		//* Set the page number on the queryParams object
		this.queryParams.page.number = this.page;

		//* Set the filter object's search property to equal the current searchTerm property
		this.queryParams.filter['search'] = this.searchTerm;

		//* Make query request
		return this.store
			.query('message', { caseId: this.caseId, params: this.queryParams })
			.then((res) => {
				//* Update meta data
				this.meta = res.meta;

				//* Push in new messages
				this.caseMessages.pushObjects(res.toArray());
				this.set('errorFetchingMessages', false);
			})
			.catch(() => {
				this.set('errorFetchingMessages', true);
			})
			.finally(() => {
				//* In the next run loop we will set the isLoading state property to false
				next(() => {
					this.isLoading = false;
					this.checkIfStillInViewPort();
				});
			});
	}

	async handleIncomingMessage(newMessage) {
		if (
			!newMessage ||
			(await newMessage.case.get('id')) != this.theCase.get('id')
		)
			return;

		//* Check to see if the message is already in the list
		const message = this.caseMessages.find((msg) => {
			return newMessage.id == msg.id;
		});

		if (message) return;

		this.caseMessages.unshiftObject(newMessage);
		return;
	}

	/* Lifecycle */
	didInsertElement() {
		this.global.set('mobileClientMessagingTab', this);

		super.didInsertElement(...arguments);

		this.setupInViewport();

		this.loaderInViewPort = true;

		if (this?.caseId) {
			this.initMessages();
		}

		if (this?.shouldSendWelcomeMessage) {
			this.send('sendWelcomeMessage');
		}

		//* Setup the incoming message handler on the pubnub newMessageCallback
		this.pubnub.newMessageCallbacks.push((...args) => {
			this.handleIncomingMessage(...args);
		});
		this.scrollToNewest();
	}

	willDestroy() {
		// need to manage cache yourself if you don't use the mixin
		const loader = document.getElementById('loader');
		this.inViewport.stopWatching(loader);

		this.global.set('mobileClientMessagingTab', null);

		//* We must reset the newMessageCallback to null on the pubnub service
		//* since this will no longer be loaded and we want to prevent errors
		//* and prevent useless tasks from running and wasting user resources
		this.pubnub.newMessageCallback = null;

		//? Maybe unload messages from the store to save user on resources?

		super.willDestroy(...arguments);
	}

	@action
	setupInViewport() {
		const loader = document.getElementById('message-loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	}

	@action
	toggleIsWritingMessage() {
		if (this.isWritingMessage) {
			this.set('newMessage', '');
		}
		this.set('isWritingMessage', !this.isWritingMessage);
	}

	@action
	unshiftNewMessage(newMessage) {
		this.caseMessages.unshiftObject(newMessage);
		setTimeout(() => {
			this.scrollToNewest();
		}, 500);
	}

	@action
	scrollToNewest() {
		const element = $('#message-list');
		$('html, body').scrollTop(element.height());
	}

	@action
	requestUpdateInformationMessage() {
		this.set(
			'newMessage',
			'My information in the app is incorrect. Could you change my ',
		);
		this.set('isWritingMessage', true);
	}
}
