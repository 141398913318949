/** @format */

import Controller from '@ember/controller';
import Errors from 'case-status/constants/errors';
import { inject as service } from '@ember/service';
import { defer } from 'rsvp';
import { next, debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SettingsUsers extends Controller {
	@service currentUser;
	@service notifications;
	@service ajax;
	@service permissions;
	@service store;

	page = 1;
	sort = 'name';

	@tracked meta = null;
	@tracked searchTerm = '';
	@tracked isLoading = true;

	queryChangeObserver() {
		debounce(this, this.fetchNewUsersData, 1000);
	}

	@tracked errors = null;
	@tracked showInviteModal = false;
	@tracked inviteSentErrors = null;
	@tracked inviteSentSuccess = false;
	@tracked isEditingUser = false;
	@tracked isShowingRemoveUserModal = false;
	@tracked newUserModel;
	@tracked userBeingEdited = null;
	@tracked userPermissions = [];
	@tracked userToRemove = null;

	onResolveActions = [];

	fetchNewUsersData() {
		//* Set the isLoading state property to true
		this.isLoading = true;

		//* Clear the model since this will be all new data
		this.model.clear();

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			page: {},
		};

		//TODO
		//* Set the filter property on the queryParams to our computed filters property
		queryParams.filter = {};

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		queryParams.page.size = 20;

		//* Set the sort on the queryParams to our current sortProperty
		queryParams.sort = this.sort;

		//* Set the search filter on the queryParams
		queryParams.filter['search'] = this.searchTerm;

		//* Set the path for the adapter
		queryParams.path = '/users_management';

		//* Set the queryParams
		this.queryParams = queryParams;
		this.store
			.findAll('user-permission')
			.then((res) => {
				this.userPermissions = res;
			})
			.catch((err) => {
				this.notifications.error(Errors.mapResponseErrors(err));
			});
		//TODO
		//* Query the API for usersChildren using the queryParams
		this.store.query('users-child', queryParams).then((res) => {
			const meta = res.meta;

			this.meta = meta;

			//* Clear model again to prevent duplicates
			this.model.clear();

			//* Push the new array's objects into the newly cleared model
			this.model.pushObjects(res.toArray());

			//* Reset the page count to 1
			this.page = 1;

			//* Check and call reserved actions
			for (let i = 0; i < this.onResolveActions.length; i++) {
				const resolveAction = this.onResolveActions[i];

				if (typeof resolveAction == 'function') {
					resolveAction();
				}

				//* Clear the actionQueue once all have been evaluated
				if (i == this.onResolveActions.length - 1) {
					this.onResolveActions = [];
				}
			}

			//* In the next runloop we will set the isLoading state property to false
			next(() => {
				this.isLoading = false;
			});
		});
	}

	@action refreshTable() {
		this.queryChangeObserver();
	}

	@action fetchMoreUsersData() {
		//* Do nothing if already on the last page, or past it magically in the future.
		if (!this.model || this.page >= this.meta?.total_pages || this.isLoading)
			return false;

		//* Set the isLoading state property to true
		this.isLoading = true;

		//* Increment the page number up by 1
		this.page = this.page + 1;

		//* Structure a queryParams Object to be passed to our query
		const queryParams = {
			filter: {},
			page: {},
		};

		//* Set the filter property on the queryParams to our computed filters property
		queryParams.filter = this.filters;

		//* Set the page Object's size property to 20, eventually we should allow them to be able to choose different page sizes
		queryParams.page.size = 20;

		//* Set the page number being requested
		queryParams.page.number = this.page;

		//* Set the sort on the queryParams to our current sortProperty
		queryParams.sort = this.sort;

		//* Set the search filter on the queryParams
		queryParams.search = this.searchTerm;

		//* Set path for adapter
		queryParams.path = '/users_management';

		//* Set the queryParams
		this.queryParams = queryParams;

		//TODO
		//* Query the API for usersChildren using the queryParams
		this.store.query('users-child', queryParams).then((res) => {
			const meta = res.meta;
			this.meta = meta;

			//* Push the new array's objects into the newly cleared model
			this.model.pushObjects(res.toArray());

			for (let i = 0; i < this.onResolveActions.length; i++) {
				const resolveAction = this.onResolveActions[i];

				if (typeof resolveAction == 'function') {
					resolveAction();
				}

				//* Clear the actionQueue once all have been evaluated
				if (i == this.onResolveActions.length - 1) {
					this.onResolveActions = [];
				}
			}

			//* In the next runloop we will set the isLoading state property to false
			next(() => {
				this.isLoading = false;
			});
		});
	}

	@action selectRole(role) {
		this.newUser.role = role;
	}

	@action selectFilterRole(role) {
		this.usersFilterType = role;
	}

	@action inviteNewUser(user, result = defer()) {
		const notifOptions = {
			autoClear: true,
			clearDuration: 10000,
		};

		//* Post the new user to api
		this.send('sendInvite', user, result);

		return result.promise
			.then(() => {
				//* Close the create modal
				this.showInviteModal = false;

				//* Check for a success message
				if (this.inviteSentSuccess && this.inviteSentSuccessMessage) {
					//* Pop a success toast notif
					this.notifications.success(
						this.inviteSentSuccessMessage,
						notifOptions,
					);
				}

				//* Update the users table
				this.queryChangeObserver();

				//* Check if the user has edit permissions
				if (this.permissions.get('user.update_all_users')) {
					//* Stage the edit modal action
					this.onResolveActions.push(() => {
						// this.send("toggleEditUserModal", null, newUser);
					});
				}
			})
			.catch((err) => {
				//* Show error toast with mapped errors from response
				this.notifications.error(Errors.mapResponseErrors(err), notifOptions);
			});
	}

	@action sendNewInvite() {
		this.resetNewUser();
		this.inviteSentSuccess = false;
	}

	@action toggleInviteModal() {
		this.newUserModel = this.store.createRecord('user', {
			firm: this.currentUser.get('user.firm'),
			sendInvite: true,
		});
		this.showInviteModal = !this.showInviteModal;
	}

	@action async toggleEditUserModal(usersChild, userToEdit) {
		if (usersChild) {
			this.store
				.findRecord(`${usersChild.get('type')}`, usersChild.get('childId'), {
					backgroundReload: false,
				})
				.then((user) => {
					this.userBeingEdited = {
						user,
						usersChild,
					};
					this.isEditingUser = !this.isEditingUser;
				})
				.catch((err) => {
					const notifOptions = {
						autoClear: true,
						canClose: true,
						clearDuration: 5000,
					};
					this.notifications.clearAll();
					this.notifications.error(err, notifOptions);
				});
		} else if (userToEdit) {
			this.userBeingEdited = {
				user: userToEdit,
				usersChild: userToEdit.userChild,
			};
			this.isEditingUser = !this.isEditingUser;
		} else {
			this.isEditingUser = !this.isEditingUser;
		}
	}

	@action toggleShowRemoveUserModal() {
		if (this.userBeingEdited.user.id) {
			this.userToRemove = this.userBeingEdited.user;
			this.errors = null;
			this.isShowingRemoveUserModal = !this.isShowingRemoveUserModal;
		} else {
			this.notifications.error(
				'A user is not selected, please refresh the page and try again.',
				{
					autoClear: true,
					canClose: true,
					clearDuration: 5000,
				},
			);
		}
	}

	@action removeUser(user) {
		if (user.id) {
			this.errors = null;
			const result = defer();
			this.send('deleteUser', user, result);
			return result.promise
				.then(() => {
					this.isShowingRemoveUserModal = false;
					this.notifications.success(
						`Removed ${user.get('name')} successfully!`,
						{
							autoClear: true,
							clearDuration: 7000,
						},
					);
					this.userBeingEdited = null;
					this.userToRemove = null;
					this.ajax.set('noCache', true);
					this.queryChangeObserver();
				})
				.catch((err) => {
					this.errors = Errors.mapResponseErrors(err);
					this.notifications.error(
						'An error has occurred, please refresh the page and try again.',
						{
							autoClear: true,
							canClose: true,
							clearDuration: 5000,
						},
					);
				});
		} else {
			this.notifications.error(
				'A user is not selected, please refresh the page and try again.',
				{
					autoClear: true,
					canClose: true,
					clearDuration: 5000,
				},
			);
		}
	}
}
