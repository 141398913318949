/** @format */

import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import moment from 'moment';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
	content: alias('message.content'),
	currentUser: service(),
	customBranding: service(),
	store: service(),
	tagName: '',
	chatFile: alias('message.chatFile'),
	senderName: computed('sender.name', 'message.senderName', function () {
		return this.get('sender.name') || this.get('message.senderName');
	}),
	senderInitials: computed('message.sender.initials', function () {
		return this.get('message.sender.initials');
	}),
	senderProfPic: computed('message.sender.profilePicture.url', function () {
		return this.get('message.sender.profilePicture.url');
	}),
	isFileImage: computed('message.chatFile.extension', function () {
		if (!this.message || !this.get('message.chatFile')) {
			return false;
		}
		//Only allowable image files
		return ['jpg', 'jpeg', 'png'].includes(
			this.get('message.chatFile.extension'),
		);
	}),
	unread: computed('message.readAt', function () {
		const readAt = this.get('message.readAt');

		return readAt === null;
	}),
	hideFromSearch: computed(
		'messageSearchText',
		'message.{senderName,content,chatFile}',
		'sendTime',
		function () {
			if (this.messageSearchText) {
				if (
					this.message
						.get('content')
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					this.message
						.get('senderName')
						.toLowerCase()
						.includes(this.messageSearchText.toLowerCase()) ||
					(this.sendTime &&
						this.sendTime
							.toLowerCase()
							.includes(this.messageSearchText.toLowerCase())) ||
					(this.message.get('chatFile') &&
						this.message.get('chatFile').get('fileName') &&
						this.message
							.get('chatFile')
							.get('fileName')
							.toLowerCase()
							.includes(this.messageSearchText.toLowerCase()))
				) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	),
	title: computed('message.type', function () {
		switch (this.message.type) {
			case 'nps_notification':
				return 'New NPS and Feedback Received!';
			case 'client_activation_request':
				return 'Case Activation Request';
			case 'treatment_activity':
				return 'New Treatment Activity Logged';
			case 'appointment_not_confirmed':
				return 'Appointment Confirmation Status Alert';
		}
	}),
});
