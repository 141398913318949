/** @format */

import { A } from '@ember/array';
import { resolve, reject, defer } from 'rsvp';
import { sort, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller, { inject } from '@ember/controller';
import moment from 'moment';
import { computed, observer } from '@ember/object';
import Errors from '../../../../constants/errors';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { paralegalDisplay } from 'case-status/helpers/paralegal-display';
import { dateDisplay } from '../../../../helpers/date-display';
import CaseMixin from '../../../../mixins/controller/case';
import {
	referralSources,
	freeTextOptions,
} from '../../../../constants/referral-source';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import { next } from '@ember/runloop';
import Changeset from 'ember-changeset';
import { emailRegex } from '../../../../constants/regex';

// eslint-disable-next-line ember/no-classic-classes
export default Controller.extend(CaseMixin, {
	date: null,
	currentUser: service('current-user'),
	permissions: service(),
	session: service('session'),
	ajax: service(),
	company: service(),
	caseController: inject('app.firm.case'),
	scroller: service(),
	global: service(),
	referralSources,
	freeTextOptions,
	shouldUpdateMessages: false,
	languages: null,

	casePaa: alias('practiceAreaAutomations'),
	practiceAreaAutomations: computed(
		'theCase.automations.@each.isDeleted',
		function () {
			const automations = this.theCase.get('automations') || [];
			return automations.filter((a) => {
				return a.automationTemplate?.get('id') && !a.isDeleted;
			});
		},
	),

	isUserOnCase: computed('theCase.users.[]', 'currentUser.user', function () {
		const theCase = this.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}),

	caseAutomations: computed('theCase.actions.@each.isDeleted', function () {
		const automations = this.theCase.get('actions') || [];
		return automations.filter((a) => {
			return !a.automationTemplate?.get('id') && !a.isDeleted;
		});
	}),

	activationFailedReason: computed(
		'theCase.activationFailedReason',
		'permissions.firmSettings.case_activation_quota',
		function () {
			let reason = this.theCase.get('activationFailedReason');
			let quota = this.permissions.firmSettings?.case_activation_quota;
			if (quota && reason) {
				return reason.replace('{quota}', quota);
			}
			return undefined;
		},
	),

	init() {
		this._super(...arguments);

		this.set(
			'date',
			dateDisplay([
				this.theCase?.get('date'),
				this.currentUser.get('user.firm'),
			]),
		);
		this.set(
			'automations_per_practice_area_count',
			this.permissions.firmSettings?.automations_per_practice_area_count,
		);
	},

	// eslint-disable-next-line ember/require-computed-macros
	automationsQuotaExceeded: computed(
		'currentUser.isPortalCustomer',
		'permissions.firmSettings.automations_per_practice_area_quota_exceeded',
		function () {
			return (
				this.currentUser.isPortalCustomer &&
				this.permissions.firmSettings
					?.automations_per_practice_area_quota_exceeded
			);
		},
	),

	async updateDynamicSetting(recentWrite) {
		this.permissions.fetchDynamicFirmSettings({
			setting: 'automations_per_practice_area',
			case_type_id: this.theCase.get('caseType.id'),
			case_id: this.theCase.get('id'),
			session: recentWrite ? 'main' : 'replica',
		});
	},
	selectedFirmLanguage: computed(
		'currentUser.user.firm.firmSettings.firmSettings.language',
		'languages',
		function () {
			const languages = this.languages || [];
			let firmLanguage = languages.find((lan) =>
				lan.language.includes(
					this?.currentUser?.user
						?.get('firm.firmSettings.firmSettings.language')
						?.toLowerCase(),
				),
			);

			return firmLanguage;
		},
	),

	selectedTargetLanguage: computed('theCase.targetLanguage', 'languages', {
		get() {
			const languages = this.languages || [];
			const searchLanguage = this.theCase.get('targetLanguage')
				? this.theCase.get('targetLanguage')
				: 'en';
			let targetLanguage = languages.find((lan) =>
				lan.language.includes(String(searchLanguage).toLowerCase()),
			);

			targetLanguage = targetLanguage
				? targetLanguage
				: languages.find((lan) => lan.language == 'en');
			return targetLanguage;
		},
		set(path, value) {
			const newValue = value.language ? value.language : value;
			this.set('theCase.targetLanguage', newValue);

			const languages = this.languages || [];

			let targetLanguage = languages.find((lan) =>
				lan.language.includes(
					String(this.theCase.get('targetLanguage')).toLowerCase(),
				),
			);

			// returns targetLanguage as an object rather than string
			targetLanguage = targetLanguage
				? targetLanguage
				: languages.find((lan) => lan.language == 'en');

			return targetLanguage;
		},
	}),

	subNavOptions: computed(
		'user',
		'theCase.newMessages',
		'showScheduledMessageTab',
		function () {
			const options = [
				{ text: 'Messages', value: 'Messages' },
				{ text: 'Documents', value: 'documents' },
				{ text: 'Automation', value: 'automation' },
				{ text: 'Checklist', value: 'checklist' },
			];
			if (this.showScheduledMessageTab)
				options.push({ text: 'Scheduled Messages', value: 'scheduledMessage' });
			if (this.theCase?.get('newMessages'))
				options[0][
					'tag'
				] = `<span class="new-messages-alert"><img src="img/new-messages.svg" class="new-messages-icon" alt="new message icon"></span>`;

			if (this.theCase.get('caseSettings.enable_activity_tracking')) {
				options.shift({ text: 'Activity', value: 'Activity' });
			}

			return options;
		},
	),
	showCaseSubnavModal: false,
	caseClientsTableHeight: computed('theCase.clients.length', function () {
		const numClients = this.theCase?.get('clients.length');
		const maxHeight = 380;
		const minHeight = 240;
		let height = 0;
		if (this.theCase?.get('clients.length') > 6) {
			height = maxHeight;
		} else if (this.theCase?.get('clients.length') < 4) {
			height = minHeight;
		} else {
			height = numClients * (maxHeight / 5);
		}

		return `${height}px`;
	}),
	formattedParalegals: computed('sortedParalegals', function () {
		const firm = this.currentUser.get('user.firm');
		const noParalegal = {
			id: 0,
			name: `No ${paralegalDisplay([firm]).toLowerCase()}`,
		};
		let formattedParalegals = this.sortedParalegals;
		formattedParalegals.unshiftObject(noParalegal);
		return formattedParalegals;
	}),
	sortedParalegalsOnCase: sort('theCase.paralegals', 'lastNameSort'),
	sortedAttorneysOnCase: sort('theCase.attorneys', 'lastNameSort'),
	isEditingCaseInformation: false,
	isEditingClientInformation: false,
	isEditingOnHoldMessage: false,
	isSchedulingMessage: false,
	isParalegal: computed('currentUser.user', function () {
		return this.currentUser.get('user')?.constructor?.modelName === 'paralegal';
	}),
	isAttorney: computed('currentUser.user', function () {
		return this.currentUser.get('user')?.constructor?.modelName === 'attorney';
	}),
	hasTransactions: computed('transactions.[]', 'theCase.id', function () {
		if (!this.transactions) {
			return false;
		}
		const transactions = this.transactions;
		const caseId = this.theCase?.get('id');

		return transactions.some((transaction) => {
			return transaction.internal_id == caseId;
		});
	}),
	hasUncheckedItems: computed('theCase.sortedChecklistItems.[]', function () {
		return this.theCase?.get('sortedChecklistItems').filter((item) => {
			return !item.completedDate && !item.isDeleted;
		});
	}),

	isUpdatingStatus: false,
	showGroupNameError: false,
	notifications: service(),
	onHoldExplanation: null,
	showCloseCaseModal: false,
	showDeleteCaseModal: false,
	showEditGroupName: false,
	showClientMessagingDisabled: false,
	sortedAttorneys: sort('attorneys', 'lastNameSort'),
	sortedClients: sort('theCase.clients', 'lastNameSort'),
	sortedParalegals: sort('paralegals', 'lastNameSort'),
	sortedCaseTypes: sort('caseTypes', 'caseTypeSort'),
	sortedStatuses: sort('statuses', 'statusSort'),
	caseTypeSort: ['name'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	statusSort: ['number'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	lastNameSort: ['lastName'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	statuses: alias('theCase.caseType.caseStatuses'),
	downloadTextSuccess: false,
	downloadTextErrors: false,
	clientSearchText: null,

	automationActive: false,
	documentsActive: false,

	scheduleMessagesActive: false,
	isCreatingNewAction: false,
	checklistActive: false,

	doNotShowOnboardingModal: false,

	paralegalCountString: computed('theCase.paralegals.[]', function () {
		const length = this.theCase?.get('paralegals.length');
		const firm = this.currentUser.get('user.firm');
		return paralegalDisplay([firm, length, false]);
	}),

	attorneyCountString: computed('theCase.attorneys.[]', function () {
		const length = this.theCase?.get('attorneys.length');
		const firm = this.currentUser.get('user.firm');
		return attorneyDisplay([firm, length]);
	}),

	caseTypes: computed('model', 'store', function () {
		//TODO Move this to the init, since it only needs to be called once
		return this.store.findAll('caseType');
	}),

	showScheduledMessageTab: computed(
		'theCase.unsentScheduledMessagesCount',
		function () {
			return this.theCase.get('unsentScheduledMessagesCount') > 0;
		},
	),

	scheduledMessagesSort: ['scheduledMessageSendDate'], //TODO add comment to get rid of red line

	sortedScheduledMessages: sort(
		'theCase.scheduledMessages',
		'scheduledMessagesSort',
	),

	scheduledMessages: computed('sortedScheduledMessages', function () {
		const sortedScheduledMessages = this.sortedScheduledMessages || [];
		const tz = this.currentUser.get('user.firm.timezone') || 'America/New_York';
		const now = moment(moment.now()).tz(tz, true);

		const filteredScheduledMessages = sortedScheduledMessages.filter(
			(scheduledMessage) => {
				const scheduled = scheduledMessage.scheduledMessageSendDate;
				const sent = scheduledMessage.sentAt;
				const isPast = moment(scheduled).isBefore(now);

				//* If it is not scheduled, or already sent, or scheduled in the past filter out.
				return !scheduled || sent || isPast ? false : true;
			},
		);

		return filteredScheduledMessages;
	}),

	otherReferralSource: computed(
		'theCase.referralSource',
		'freeTextOptions',
		function () {
			if (this.theCase && this.theCase.get('referralSource')) {
				const refSource = this.theCase.get('referralSource.label')
					? this.theCase.get('referralSource.label')
					: this.theCase.get('referralSource');

				const freeTextOptions = this.freeTextOptions;

				const testRefSource = `${refSource}`;
				const dashIndex = testRefSource.indexOf('-');
				const hasDash = dashIndex != -1;
				const workingRefSource = hasDash
					? testRefSource.slice(0, dashIndex - 1).trim()
					: testRefSource;

				return freeTextOptions.includes(workingRefSource);
			}
			return false;
		},
	),

	npsData: computed(
		'theCase.netPromoterScores.[{stage_1,feedbackStage_1,stage_2,feedbackStage_2,stage_3,feedbackStage_3,stage_4,feedbackStage_4,stage_5,feedbackStage_5,stage_6,feedbackStage_6,stage_7,feedbackStage_7,stage_8,feedbackStage_8,stage_9,feedbackStage_9,stage_10,feedbackStage_10,stage_11,feedbackStage_11,stage_12,feedbackStage_12}]',
		'statuses.length',
		function () {
			const scores = this.theCase?.get('netPromoterScores');

			let chartData = {
				labels: [],
				datasets: [],
			};

			let totalDataSet = {
				total: 0,
				label: 'Total',
				labels: [],
				dataset: [0, 0, 0, 0, 0, 0],
			};

			let totalIndexCount = [0, 0, 0, 0, 0, 0];

			scores.forEach((npsScore) => {
				// Pull each stage from the model and set it in an array that we can loop through
				let stageOne = npsScore.get('stage_1');
				let stageOneFeedback = npsScore.get('feedbackStage_1');
				let stageTwo = npsScore.get('stage_2');
				let stageTwoFeedback = npsScore.get('feedbackStage_2');
				let stageThree = npsScore.get('stage_3');
				let stageThreeFeedback = npsScore.get('feedbackStage_3');
				let stageFour = npsScore.get('stage_4');
				let stageFourFeedback = npsScore.get('feedbackStage_4');
				let stageFive = npsScore.get('stage_5');
				let stageFiveFeedback = npsScore.get('feedbackStage_5');
				let stageSix = npsScore.get('stage_6');
				let stageSixFeedback = npsScore.get('feedbackStage_6');
				let stageSeven = npsScore.get('stage_7');
				let stageSevenFeedback = npsScore.get('feedbackStage_7');
				let stageEight = npsScore.get('stage_8');
				let stageEightFeedback = npsScore.get('feedbackStage_8');
				let stageNine = npsScore.get('stage_9');
				let stageNineFeedback = npsScore.get('feedbackStage_9');
				let stageTen = npsScore.get('stage_10');
				let stageTenFeedback = npsScore.get('feedbackStage_10');
				let stageEleven = npsScore.get('stage_11');
				let stageElevenFeedback = npsScore.get('feedbackStage_11');
				let stageTwelve = npsScore.get('stage_12');
				let stageTwelveFeedback = npsScore.get('feedbackStage_12');

				let stageRawData = [
					stageOne,
					stageTwo,
					stageThree,
					stageFour,
					stageFive,
					stageSix,
					stageSeven,
					stageEight,
					stageNine,
					stageTen,
					stageEleven,
					stageTwelve,
				];
				let stageRawFeedback = [
					stageOneFeedback,
					stageTwoFeedback,
					stageThreeFeedback,
					stageFourFeedback,
					stageFiveFeedback,
					stageSixFeedback,
					stageSevenFeedback,
					stageEightFeedback,
					stageNineFeedback,
					stageTenFeedback,
					stageElevenFeedback,
					stageTwelveFeedback,
				];

				const stageLabels = [];
				let stageData = [];
				let stageFeedback = [];
				let currentScore = 'No Score Yet';

				// Don't want the dataset to have null values, so we loop through
				// the raw data array and set values for each stage with a score
				stageRawData.forEach((stage, index) => {
					//* Exclude null feedback from the chart
					if (index >= this?.statuses.get('length') || stage == null) return;
					chartData.labels[index] = `Stage ${index + 1}`;
					totalDataSet.labels[index] = `Stage ${index + 1}`;
					totalDataSet.dataset[index] += stage;
					stageData.push(stage);
					stageLabels.push('Stage ' + (index + 1));
					stageFeedback.push(stageRawFeedback[index]);
					if (typeof stage === 'number') {
						totalIndexCount[index] += 1;
						currentScore = stage;
					}
				});

				const client = npsScore.get('client');

				// Now we set and return the formatted chart data
				const dataset = {
					total: currentScore,
					label: client ? client.get('name') : 'N/A', // in case client is not included in payload
					labels: stageLabels,
					feedback: stageFeedback,
					dataset: stageData,
				};
				chartData.datasets.push(dataset);
			});

			if (chartData.datasets.length === 0) {
				return {};
			} else if (chartData.datasets.length === 1) {
				return chartData.datasets[0];
			} else {
				// when there are multiple clients, some may not have entered an NPS score,
				// this removes those items from the average score of the firm insights module
				const validDatasets = chartData.datasets.filter((dataset) => {
					return typeof dataset.total != 'string';
				});

				const averageScore = Math.floor(
					validDatasets
						.map((dataset) => dataset.total)
						.reduce((prev, next) => prev + next, 0) / validDatasets.length,
				);
				chartData.total = averageScore;
				totalDataSet.total = averageScore;

				totalIndexCount.forEach((count, index) => {
					if (count) {
						const score = totalDataSet.dataset[index];
						totalDataSet.dataset[index] = Math.floor(score / count);
					} else {
						//Change the 0 to a null so a line does not render on that chart
						totalDataSet.dataset[index] = null;
					}
				});

				chartData.datasets.unshift(totalDataSet);
				return chartData;
			}
		},
	),

	nameMatcher(option, searchTerm) {
		return option.get('name').indexOf(searchTerm);
	},

	saveCaseModel() {
		return this.theCase
			?.save()
			.then((theCase) => {
				this.set('errors', null);
				//* Toggle is back and forth to reload the case message component
				this.set('shouldUpdateMessages', true);
				return resolve(theCase);
			})
			.catch((response) => {
				this.set('errors', Errors.mapResponseErrors(response));
				return reject(response);
			})
			.finally(() => {
				next(() => {
					this.set('shouldUpdateMessages', false);
				});
			});
	},

	saveClientModel() {
		return this.theCase?.get('client').then((client) => {
			return client
				.save()
				.then(() => {
					this.set('errors', null);
					return resolve(client);
				})
				.catch((response) => {
					this.set('errors', Errors.mapResponseErrors(response));
					return reject();
				});
		});
	},

	filteredClients: computed('clientSearchText', 'sortedClients', function () {
		const clients = this.sortedClients;
		if (!clients) {
			return null;
		}
		const clientSearchText = this.clientSearchText;
		if (clientSearchText && clientSearchText !== '') {
			return A(
				clients.filter((client) => {
					return client
						.get('name')
						.toLowerCase()
						.includes(clientSearchText.toLowerCase());
				}),
			);
		}
		return clients;
	}),

	unopenedClientCount: computed('sortedClients', function () {
		return this.sortedClients.filter((client) => {
			return client.get('lastOpened') === null;
		}).length;
	}),

	unreadCount: computed('model.theCase.newMessages', function () {
		return this.model.theCase?.get('newMessages');
	}),

	checkOnboarding(deferredAction, ...deferredActionParams) {
		const activatedDate = this.theCase?.get('activatedDate');
		const doNotShowOnboardingModal = this.doNotShowOnboardingModal;
		const isDeferred = this.deferredAction;

		// If they have already selected do not show again, then just break out now
		if (doNotShowOnboardingModal) return false;

		// If the case is not onboarding (ie activated), then go ahead and break out now
		if (activatedDate) return false;

		// If there is already a deferred action queued, break out;
		if (isDeferred) return false;

		this.toggleProperty('showOnboardingModal');

		this.set('deferredAction', deferredAction);
		this.set('deferredActionParams', deferredActionParams);

		return true;
	},
	firmSettingObserver: observer(
		'permissions.firmSettings.automations_per_practice_area_count',
		function () {
			this.set(
				'automations_per_practice_area_count',
				this.permissions.firmSettings?.automations_per_practice_area_count,
			);
		},
	),
	userObserver: observer('currentUser.user', function () {
		if (this.currentUser.user !== null) {
			//Init user props
			this.set('sendAsPrimary', this.currentUser.user.sendAsPrimary);
		}
	}),

	theCase: null,
	modelObserver: observer('model.theCase', function () {
		if (!this.model) return;
		this.setChangeset();
	}),

	setChangeset() {
		const changeset = new Changeset(this.model.theCase);
		this.set('theCase', changeset);
	},

	rollbackAttributes() {
		if (
			this.model &&
			this.model.theCase &&
			this.model.theCase.get('rollbackAttributes') &&
			typeof this.model.theCase.get('rollbackAttributes') === 'function'
		) {
			this.model.theCase.rollbackAttributes();
		}
		this.setChangeset();
	},

	isWritingMessageObserver: observer('isWritingMessage', function () {
		this.set(
			'global.casePauseTimerConditions.isWritingMessage',
			this.isWritingMessage,
		);
	}),

	isEditingCaseInformationObserver: observer(
		'isEditingCaseInformation',
		function () {
			this.set(
				'global.casePauseTimerConditions.isEditingCaseInfo',
				this.isEditingCaseInformation,
			);
		},
	),

	queryParams: ['currentTab'],

	currentTab: 'info',

	successfulInvite(name) {
		this.notifications.success(
			name ? `Sent invite to ${name}` : `Invite successfully sent!`,
			{
				autoClear: true,
			},
		);
		this.set('downloadTextSuccess', true);
		setTimeout(() => {
			if (this.downloadTextSuccess) {
				this.set('downloadTextSuccess', false);
			}
		}, 3500);
	},

	showLinkAsCollaborator: computed(
		'otherReferralSource',
		'permissions.user.collaboration_tab',
		function () {
			if (!this.permissions.user.collaboration_tab) return false;

			const isApplicable = this.otherReferralSource;

			return isApplicable;
		},
	),
	disableLinkAsCollaborator: computed('otherReferralSourceText', function () {
		const val = `${this.otherReferralSourceText}`;
		const isEmail = emailRegex.test(val);
		return !isEmail;
	}),
	shouldLinkAsCollaborator: false,

	linkReferralSourceAsCollaborator() {
		const referralSourceEmail = `${this.otherReferralSourceText}`;
		this.transitionToRoute(
			`app.firm.case.collaboration`,
			this.model.theCase.id,
		).then((collabRoute) => {
			collabRoute.controller.setProperties({
				showAddCollaboratorModal: true,
				referralSourceEmail,
			});
		});
	},

	referralSourceAlreadyLinked: computed(
		'otherReferralSourceText',
		'model.chats.length',
		'showLinkAsCollaborator',
		'isEditingCaseInformation',
		function () {
			if (
				!this.model ||
				!this.model.chats ||
				!this.model.chats.length ||
				!this.showLinkAsCollaborator ||
				!this.isEditingCaseInformation
			)
				return false;

			const chats = this.model.chats.toArray();
			let isLinked = false;

			for (let i = 0; i < chats.length; i++) {
				//* Break the loop if isLink is already marked as true
				if (isLinked) break;
				const chat = chats[i];
				const members = chat.get('members').toArray();
				const foundMember = members.find((member) => {
					const memberEmail = member?.get('emailAddress');
					return memberEmail == this.otherReferralSourceText;
				});
				if (foundMember) {
					isLinked = true;
				}
			}

			return isLinked;
		},
	),

	showCancelEditCaseInformationWarningModal: false,

	// eslint-disable-next-line ember/no-actions-hash
	actions: {
		rollbackAttributes() {
			this.rollbackAttributes();
		},
		eSignCallback() {
			const theCase = this.model.theCase; //* Must use the direct model.theCase since Changeset doesn't have the reload method
			theCase.reload();
		},

		async refreshCase() {
			await this.model.theCase.reload();
		},

		showCaseInfo() {
			this.set('currentTab', CURRENT_TAB_INFO);
		},

		showCollaboration() {
			this.set('currentTab', CURRENT_TAB_COLLAB);
		},

		//Handles hiding onscreen dropdowns when the mouse is clicked if they are visible
		onBackgroundClick(evt) {
			if (
				this.showClientActionsModal &&
				evt.target.id !== 'client-actions' &&
				evt.target.offsetParent &&
				evt.target.offsetParent.className.indexOf('client-actions-modal') === -1
			) {
				this.set('showClientActionsModal', false);
			}

			if (
				this.showMessageActionsModal &&
				evt.target.id !== 'message-actions' &&
				evt.target.offsetParent &&
				evt.target.offsetParent.className.indexOf('message-actions-modal') ===
					-1
			) {
				this.set('showMessageActionsModal', false);
			}
		},

		onTransactionClick() {
			const caseId = this.theCase?.get('id');

			const invoice = this.transactions.find(
				(transaction) => transaction.internal_id == caseId,
			);

			if (invoice && invoice.internal_id)
				this.transitionToRoute('app.firm.firm-insights', {
					queryParams: { invoiceId: String(invoice.internal_id) },
				});
		},

		selectDocumentSort(option) {
			this.set('selectedDocumentSortOption', option);
		},

		deleteScheduledMessage(message, result) {
			const routeResult = defer();
			this.send('deleteMessage', message, routeResult);
			routeResult.promise.then(() => {
				this.send('toggleActiveCardSection', 'messages');

				this.theCase.set(
					'unsentScheduledMessagesCount',
					this.theCase.get('unsentScheduledMessagesCount') - 1,
				);

				result.resolve();
			});
		},

		editScheduledMessage(message, content, selectedTime, result) {
			this.send('editMessage', message, content, selectedTime, result);
		},

		addClientsToCase(clientsToAdd) {
			const result = defer();
			const theCase = this.theCase;
			const filteredClients = clientsToAdd.filter((client) => {
				return !theCase.get('clients').includes(client);
			});

			theCase?.get('clients').pushObjects(filteredClients);
			theCase
				?.save()
				.then(() => {
					result.resolve();
					this.toggleProperty('showAddClientsModal');
				})
				.catch((err) => {
					this.rollbackAttributes();
					this.set('errors', err);
					result.reject(err);
					this.set('showAddClientsErrors', true);
				});

			return result.promise;
		},

		addParalegalsToCase(paralegalsToAdd) {
			const result = defer();
			const theCase = this.theCase;

			theCase.get('paralegals').pushObjects(paralegalsToAdd);
			theCase
				.save()
				.then(() => {
					this.toggleProperty('showAddParalegalsModal');
					result.resolve();
				})
				.catch((err) => {
					this.rollbackAttributes();
					this.set('errors', err);
					result.reject(err);
					this.set('showAddParalegalsErrors', true);
				});

			return result.promise;
		},

		addAttorneysToCase(attorneysToAdd) {
			const result = defer();
			const theCase = this.theCase;

			theCase.get('attorneys').pushObjects(attorneysToAdd);
			theCase
				.save()
				.then(() => {
					this.toggleProperty('showAddAttorneysModal');
					result.resolve();
				})
				.catch((err) => {
					this.rollbackAttributes();
					this.set('errors', err);
					result.reject(err);
					this.set('showAddAttorneysErrors', true);
				});

			return result.promise;
		},

		cancelEditing() {
			this.send('cancelEditCaseInformation');
			this.send('cancelEditCaseStatus');
			this.send('cancelEditOnHoldExplanation');
		},

		attemptCancelEditCaseInformation() {
			if (
				this.isEditingCaseInformation &&
				!this.showCancelEditCaseInformationWarningModal
			) {
				this.set('showCancelEditCaseInformationWarningModal', true);
			}
		},

		toggleShowCancelEditCaseInformationWarningModal() {
			// delay by 50ms since this runs before the clickOutside calls "attemptCancelEditCaseInformation" which just causes it to reopen the modal, making it look like the Keep Editing button does nothing
			setTimeout(() => {
				this.toggleProperty('showCancelEditCaseInformationWarningModal');
			}, 50);
		},

		cancelEditCaseInformation() {
			this.set('showCancelEditCaseInformationWarningModal', false);
			//* No need to cancel if not editing, just in case a rogue call happens
			if (!this.isEditingCaseInformation) return;
			this.rollbackAttributes();

			//Ember data does not track relationships so we have to handle it ourselves
			if (this.oldCaseType) {
				this.set('theCase.caseType', this.oldCaseType);
			}

			this.set('isEditingCaseInformation', false);
			this.set('showCaseError', false);
		},

		cancelEditCaseStatus() {
			this.set('isUpdatingStatus', false);
			this.set('showStatusError', false);
			if (this.oldCaseStatus) {
				this.set('theCase.caseStatus', this.oldCaseStatus);
				this.set('oldCaseStatus', null);
			}
		},

		cancelEditOnHoldExplanation() {
			this.set('showOnHoldError', false);
			this.set('isEditingOnHoldExplanation', false);
			this.set('isOnHold', this.theCase?.get('onHold'));
		},

		closeCase(deleteAutomations, result) {
			this.set('theCase.closed', true);
			this.saveCaseModel()
				.then(() => {
					this.send('toggleShowCloseCaseModal');
					this.set('isClosed', true);
					if (deleteAutomations) {
						this.theCase?.get('actions').forEach((action) => {
							action.destroyRecord();
						});
					}

					if (result) {
						result.resolve();
					}
				})
				.catch((err) => {
					this.set('showCloseCaseErrors', true);
					this.set('theCase.closed', false);
					if (result) {
						result.reject(err);
					}
				});
		},

		closeCaseAndRequestReview(deleteAutomations, result) {
			this.send('requestReview');
			this.send('closeCase', deleteAutomations, result);
		},

		deleteCase() {
			this.model.theCase
				.destroyRecord()
				.then(() => {
					//* Unload the case so that way the store doesn't confuse its deleted state with actual deletion
					this.store.unloadRecord(this.model.theCase);

					this.transitionToRoute('app.firm.cases');
					this.notifications.success(
						`${capitalize(
							dynamicCaseLabel([this.company]),
						)} has been removed successfully!`,
						{
							autoClear: true,
							clearDuration: 7000,
							cssClasses: 'notification--success',
						},
					);
					this.send('toggleShowDeleteCaseModal');
					this.set('showDeleteCaseErrors', true);
				})
				.catch(() => {
					this.notifications.error(
						`There was an error removing this ${dynamicCaseLabel([
							this.company,
						])}`,
						{
							autoClear: true,
							clearDuration: 7000,
						},
					);
				});
		},

		editCaseInformation() {
			this.set('readableId', this.theCase?.get('readableId'));

			//* We format the date away from the format used in the DB
			//* and conditionally format it based on the firm's EU status
			const date = this.theCase?.get('date');
			if (moment(date).isValid()) {
				const format = this.theCase?.get('firm.isEu')
					? 'DD-MM-YYYY'
					: 'MM/DD/YYYY';
				this.set('date', moment(date, 'YYYY-MM-DD').format(format));
			} else {
				this.set('date', null);
			}

			this.set('attorneys', this.store.findAll('attorney'));
			this.set('paralegals', this.store.findAll('paralegal'));

			//* Since Ember Data doesn't keep historical track of relationships, we'll need
			//* to store the current caseType in a property to be able to manually
			//* rollback that relationship if the edit is cancelled or transitioned
			//* away from this route or replaced
			this.set('oldCaseType', this.theCase?.get('caseType'));

			//* Let's reformat the referralSource to an object for the dropdown and text input to accept
			//* First check to see if the property exists, if not then we skip this
			if (this.theCase?.get('referralSource')) {
				//* Then check to see if the string version of whatever the property value is
				//* includes the "-", so we know it has free text in it
				if (`${this.theCase?.get('referralSource')}`.includes('-')) {
					//* First we make a String clone of the property
					const refSource = `${this.theCase?.get('referralSource')}`;

					//* Then we get the index of the "-" that was concatenated in when saved
					const dashIndex = refSource.indexOf('-');

					//* Now we format the refSource into an workable Object by slicing String
					//* clones of it using the dashIndex as a frame of reference
					this.set('theCase.referralSource', {
						label: `${refSource}`.slice(0, dashIndex - 1).trim(),
						value: `${refSource}`.slice(dashIndex + 2).trim(),
					});

					//* Set the property that is used for the free text input when a freeText option is selected
					this.set(
						'otherReferralSourceText',
						`${this.theCase?.get('referralSource.value')}`,
					);
					this.set(
						'selectedReferralSource',
						this.theCase?.get('referralSource'),
					);
				} else {
					//* If it's not a freeText selection then format it into the normal
					//* workable object like the rest of the choices
					this.set('theCase.referralSource', {
						label: this.theCase?.get('referralSource'),
						value: this.theCase?.get('referralSource'),
					});
					this.set(
						'selectedReferralSource',
						this.theCase?.get('referralSource'),
					);
				}
			}

			//* Update the Editing State to true
			this.set('isEditingCaseInformation', true);
		},

		editCaseStatus() {
			const isOnboarding = this.checkOnboarding('editCaseStatus');

			if (isOnboarding) return;

			this.set('isUpdatingStatus', true);
			this.set('oldCaseStatus', this.theCase?.get('caseStatus'));
		},

		editOnHoldExplanation() {
			this.set('onHoldExplanation', this.theCase?.get('onHoldExplanation'));
			this.set('isEditingOnHoldExplanation', true);
			this.set('showOnHoldError', false);
		},

		removeParalegalFromCase(paralegal) {
			const theCase = this.theCase;
			theCase.get('paralegals').removeObject(paralegal);
			return theCase.save();
		},

		removeAttorneyFromCase(attorney) {
			const theCase = this.theCase;
			theCase.get('attorneys').removeObject(attorney);
			return theCase.save();
		},

		makePrimary(attorney) {
			const theCase = this.theCase;
			const thisPrimaryAttorney = theCase.get('primaryAttorney');
			theCase.set('primaryAttorney', attorney);
			return theCase
				.save()
				.then(() => {
					this.notifications.success(
						`Primary ${attorneyDisplay([
							this.currentUser.get('user.firm'),
						]).toLowerCase()} has been changed!`,
						{
							autoClear: true,
							clearDuration: 7000,
							cssClasses: 'notification--success',
						},
					);
				})
				.catch(() => {
					this.notifications.error(
						`There was an error setting primary ${attorneyDisplay([
							this.currentUser.get('user.firm'),
						]).toLowerCase()}`,
						{
							autoClear: true,
							clearDuration: 7000,
						},
					);
					theCase.set('primaryAttorney', thisPrimaryAttorney);
				});
		},

		removeClientFromCase(client) {
			const theCase = this.theCase;
			theCase.get('clients').removeObject(client);
			return theCase.save();
		},

		sendInviteToClient(row) {
			this.send(
				'sendDownloadLinkTextToClient',
				row.get('id'),
				this.theCase?.get('id'),
			);
			this.successfulInvite(`${row.get('firstName')} ${row.get('lastName')}`);
		},

		sendInviteToCase(result = defer()) {
			this.send(
				'sendDownloadLinkTextToNotOpenedClients',
				this.theCase?.get('id'),
				result,
			);
			return result.promise
				.then(() => {
					let clients = this.theCase.get('clients').map((client) => {
						client.set('invite', true);
						return client;
					});

					this.set('theCase.clients', clients);
				})
				.catch((err) => {
					this.set('errors', Errors.mapResponseErrors(err));
				});
		},

		sendOnboardingInviteToCase() {
			const result = defer();
			this.send('sendInviteToCase', result);

			return result.promise
				.then(() => {
					this.send('continueOnboarding');
				})
				.catch((err) => {
					this.set('errors', Errors.mapResponseErrors(err));
				});
		},

		updateClientContactInfo(client) {
			this.transitionToRoute('app.firm.clients').then((clientsRoute) => {
				clientsRoute.controller.set('selectedClient', client);
				clientsRoute.controller.set('searchString', client.get('name'));
				clientsRoute.controller.set('isShowingEditClientModal', true);
			});
		},

		reopenCase() {
			this.set('theCase.closed', false);
			return this.saveCaseModel()
				.then(() => {
					this.set('isClosed', false);
				})
				.catch(() => {
					this.set('theCase.closed', true);
					this.notifications.error(
						`Unable to reopen ${dynamicCaseLabel([
							this.company,
						])}. Please try again later`,
						{
							autoClear: true,
							clearDuration: 5000,
						},
					);
				});
		},

		saveCaseInformation() {
			let date = this.date;

			if (date) {
				const format = this.theCase?.get('firm.isEu')
					? 'DD-MM-YYYY'
					: 'MM/DD/YYYY';
				date = moment(this.date, format);
			}

			if (this.theCase?.get('caseStatus') === null) {
				this.set('showStatusError', true);
				this.set('caseStatusErrors', 'You must set a case status.');
			} else {
				let isValid = this.theCase.get('validations.isValid');

				if (
					this.theCase.get('onHold') &&
					!this.theCase.get('onHoldExplanation')
				) {
					isValid = false;
				}

				if (isValid) {
					this.set('showStatusError', false);
					this.set(
						'theCase.readableId',
						this.readableId && `${this.readableId}`.trim()
							? `${this.readableId}`.trim()
							: this.theCase.get('id'),
					);

					if (moment(date).isValid()) {
						this.set('theCase.date', moment(date).format('YYYY-MM-DD'));
					} else {
						this.set('theCase.date', null);
					}

					//* Reconfigure the referralSource object to a string for the API
					if (
						this.theCase?.get('referralSource') &&
						this.theCase?.get('referralSource.value')
					) {
						const refSource = this.theCase?.get('referralSource');
						this.send('selectedReferralSource', refSource);
						this.set(
							'theCase.referralSource',
							this.theCase?.get('referralSource.value'),
						);
					}

					return this.saveCaseModel()
						.then(() => {
							this.set('isEditingCaseInformation', false);
							this.set('showCaseError', false);
							this.set('oldAttorney', null);
							// Set the old case type so that when rollback is called we point this casetype
							this.set('oldCaseType', this.theCase?.get('caseType'));

							if (this.shouldLinkAsCollaborator) {
								this.linkReferralSourceAsCollaborator();
								this.set('shouldLinkAsCollaborator', false);
							}
						})
						.catch(() => {
							this.set('showCaseError', true);
						});
				} else {
					this.set('showCaseError', true);
					return reject();
				}
			}
		},

		saveOnHoldExplanation(result) {
			if (this?.onHoldExplanation) {
				this.set('theCase.onHoldExplanation', this.onHoldExplanation);
				this.set('theCase.onHold', true);
				this.caseController.set('isOnHold', true);
				return this.saveCaseModel()
					.then(() => {
						this.set('isEditingOnHoldExplanation', false);
						this.set('showOnHoldError', false);
						result.resolve();
					})
					.catch(() => {
						this.set('showOnHoldError', true);
						result.reject();
					});
			} else {
				this.set(
					'onHoldErrorMessage',
					'On Hold Explanation is a required field.',
				);
				this.set('showOnHoldError', true);
				result.reject();
				return reject();
			}
		},

		saveCaseStatus() {
			return this.saveCaseModel()
				.then((val) => {
					this.set('model.theCase', val);
					this.set('isUpdatingStatus', false);
					this.set('showStatusError', false);
					this.set('oldCaseStatus', null);
					if (this.currentUser.get('user.firm.messagingOff')) {
						this.notifications.warning(
							'No "Status Update" message was sent due to firm messaging currently being disabled',
							{ autoClear: true, clearDuration: 7000 },
						);
					}
				})
				.catch(() => {
					this.set('showStatusError', true);
				});
		},

		selectClient(client) {
			this.set('theCase.client', client);
		},

		selectedAttorney(attorney) {
			this.set('theCase.attorney', attorney);
		},

		selectedParalegal(paralegal) {
			this.set('theCase.paralegal', paralegal.id === 0 ? null : paralegal);
		},

		selectedCaseType(caseType) {
			this.set('theCase.caseType', caseType);

			const currentCaseTypeId = this.model.theCase.get('caseType.id');

			if (caseType.id == currentCaseTypeId) {
				this.set('theCase.caseStatus', this.model.theCase.get('caseStatus'));
			} else {
				this.set(
					'theCase.caseStatus',
					Array.isArray(this.sortedStatuses) ? this.sortedStatuses[0] : null,
				);
			}
		},

		selectedReferralSource(referralSource) {
			const freeTextOptions = this.freeTextOptions;
			//* If it is a freeText option and there is otherReferralSourceText then concatenate
			//* then with the Label then a "-" then the otherReferralSourceText
			//* If there isn't any otherReferralSourceText then just set the value equal to the label
			if (
				referralSource.freeText ||
				freeTextOptions.includes(referralSource.label)
			) {
				referralSource.value = `${referralSource.label} ${
					this.otherReferralSourceText
						? `- ${this.otherReferralSourceText}`.trim()
						: ''
				}`.trim();
			}

			this.set('selectedReferralSource', referralSource);
			this.set('theCase.referralSource', referralSource);
		},

		selectCaseStatus(status) {
			this.set('theCase.caseStatus', status);
		},

		//? Is this still used here?
		sendChatMessage(result = defer(), chatId = 0, content = '') {
			if (chatId && content) {
				this.send('postChatMessage', chatId, content, result);
			} else {
				result.reject();
			}
		},

		toggleOnHold() {
			const isOnboarding = this.checkOnboarding('toggleOnHold');
			if (isOnboarding) return;
			const result = defer();
			if (this.isOnHold && this.theCase?.get('onHold')) {
				this.set('theCase.onHold', false);
				this.set('theCase.onHoldExplanation', null);
				this.saveCaseModel()
					.then(() => {
						this.set('showOnHoldError', false);
						this.set('isOnHold', false);
						this.caseController.set('isOnHold', false);
						result.resolve();
					})
					.catch(() => {
						this.set('showOnHoldError', true);
						this.set('theCase.onHold', true);
						this.set('isOnHold', true);
						this.caseController.set('isOnHold', true);
						result.reject();
					});
			} else if (this.isOnHold) {
				this.send('cancelEditOnHoldExplanation');
				result.resolve();
			} else {
				this.set('isOnHold', true);
				this.caseController.set('isOnHold', true);
				this.send('editOnHoldExplanation');
				result.resolve();
			}
			return result.promise;
		},

		toggleShowAddClientsModal() {
			// Cancel editing otherwise some dirty edits save when they add a Client
			this.send('cancelEditing');
			this.toggleProperty('showAddClientsModal');
			this.set('errors', null);
			this.set('showAddClientErrors', false);
		},

		toggleShowAddParalegalsModal() {
			// Cancel editing otherwise some dirty edits save when they add a Paralegal
			this.send('cancelEditing');
			this.toggleProperty('showAddParalegalsModal');
			this.set('errors', null);
			this.set('showAddParalegalErrors', false);
		},

		toggleShowAddAttorneysModal() {
			// Cancel editing otherwise some dirty edits save when they add an Attorney
			this.send('cancelEditing');
			this.toggleProperty('showAddAttorneysModal');
			this.set('errors', null);
			this.set('showAddAttorneyErrors', false);
		},

		toggleShowClientActionsModal() {
			this.toggleProperty('showClientActionsModal');
		},

		toggleShowCloseCaseModal() {
			const isModalOpen = this.showCloseCaseModal;
			const isOnboarding = isModalOpen
				? false
				: this.checkOnboarding('toggleShowCloseCaseModal');

			if (isOnboarding) return false;

			this.toggleProperty('showCloseCaseModal');
			this.set('showCloseCaseErrors', false);
		},

		toggleShowDeleteCaseModal() {
			this.toggleProperty('showDeleteCaseModal');
			this.set('showDeleteCaseErrors', false);
		},

		toggleShowEditGroupName() {
			this.toggleProperty('showEditGroupName');
		},

		toggleShowClientMessagingDisabled() {
			this.toggleProperty('showClientMessagingDisabled');
		},

		toggleShowOnboardingModal() {
			this.toggleProperty('showOnboardingModal');
			this.set('deferredAction', null);
			this.set('deferredActionParams', null);
		},

		continueOnboarding() {
			const deferredAction = this.deferredAction;
			const deferredActionParams = this.deferredActionParams;

			this.toggleProperty('showOnboardingModal');

			if (deferredAction) {
				this.send(deferredAction, ...deferredActionParams);
			}

			this.set('deferredAction', null);
			this.set('deferredActionParams', null);
		},

		editGroupName() {
			// Trim excess spaces from group name
			if (this.theCase?.get('group')) {
				this.set('theCase.group', this.theCase?.get('group').trim());
			}
			return this.saveCaseModel().then(() => {
				this.toggleProperty('showEditGroupName');
				this.set('showGroupNameError', false);
			});
		},

		cancelEditGroupName() {
			if (!this.showEditGroupName) return;
			this.rollbackAttributes();
			this.toggleProperty('showEditGroupName');
			this.set('showGroupNameError', false);
		},

		toggleActiveCardSection(section) {
			switch (section) {
				case 'activity':
					this.set('activityActive', true);
					this.set('messagesActive', false);
					this.set('documentsActive', false);
					this.set('automationActive', false);
					this.set('scheduleMessagesActive', false);
					this.set('checklistActive', false);
					break;
				case 'messages':
					this.set('activityActive', false);
					this.set('messagesActive', true);
					this.set('documentsActive', false);
					this.set('automationActive', false);
					this.set('scheduleMessagesActive', false);
					this.set('checklistActive', false);
					break;
				case 'automation':
					this.set('activityActive', false);
					this.set('messagesActive', false);
					this.set('documentsActive', false);
					this.set('scheduleMessagesActive', false);
					this.set('automationActive', true);
					this.set('checklistActive', false);
					break;
				case 'scheduledMessage':
					this.set('activityActive', false);
					this.set('scheduleMessagesActive', true);
					this.set('messagesActive', false);
					this.set('documentsActive', false);
					this.set('automationActive', false);
					this.set('checklistActive', false);
					break;
				case 'documents':
					this.set('activityActive', false);
					this.set('messagesActive', false);
					this.set('documentsActive', true);
					this.set('scheduleMessagesActive', false);
					this.set('automationActive', false);
					this.set('checklistActive', false);
					break;
				case 'checklist':
					this.set('activityActive', false);
					this.set('messagesActive', false);
					this.set('documentsActive', false);
					this.set('scheduleMessagesActive', false);
					this.set('automationActive', false);
					this.set('checklistActive', true);
					break;
				default:
					this.set('activityActive', true);
					this.set('messagesActive', false);
					this.set('documentsActive', false);
					this.set('automationActive', false);
					this.set('scheduleMessagesActive', false);
					this.set('checklistActive', false);
					break;
			}

			// sets section to title case for mobile users and close modal
			this.set(
				'selectedSubNav',
				section.charAt(0).toUpperCase() + section.slice(1),
			);
			this.toggleProperty('showCaseSubnavModal');
			this.incrementProperty('activityCheck');
		},

		toggleShowCaseSubnavModal() {
			this.toggleProperty('showCaseSubnavModal');
		},

		saveAction(action, result) {
			action
				.save()
				.then(() => {
					result.resolve();
				})
				.catch((response) => {
					const errors = Errors.mapResponseErrors(response);
					result.reject(errors);
				});
		},

		deleteAutomation(automation, result) {
			automation
				.destroyRecord()
				.then(() => {
					result.resolve();
					this.updateDynamicSetting(true);
				})
				.catch((response) => {
					this.updateDynamicSetting(true);

					result.reject(Errors.mapResponseErrors(response));
				});
		},

		toggleIsCreatingNewAction() {
			this.toggleProperty('isCreatingNewAction');
		},

		createNewAction(action, result) {
			action.set('case', this.model.theCase);
			action
				.save()
				.then(() => {
					result.resolve();
					this.set('isCreatingNewAction', false);
					this.updateDynamicSetting(true);
				})
				.catch((response) => {
					const errors = Errors.mapResponseErrors(response);
					result.reject(errors);
					this.updateDynamicSetting(true);
				});
		},
		saveCaseModel() {
			return this.saveCaseModel();
		},
		sendWelcomeMessage() {
			this.send('toggleActiveCardSection', 'messages');
			this.set('shouldSendWelcomeMessage', true);
			this.set('shouldUpdateMessages', true);
			next(() => {
				this.set('shouldUpdateMessages', false);
			});
		},

		refreshMessages() {
			this.set('shouldUpdateMessages', true);
			this.set('messageTemplateType', null);
			next(() => {
				this.set('shouldUpdateMessages', false);
			});
		},

		sendOwnMessage() {
			this.send('toggleActiveCardSection', 'messages');
			this.set('isWritingMessage', true);
			this.scroller.scrollVertical('.progress-bar');
		},

		sendDocumentUpload() {
			this.send('toggleActiveCardSection', 'messages');
			// Prevent state where if isWritingMessage is never toggled off (i.e switching tabs)
			// the showMessageActionsModal wont show due to isWritingMessage being toggled
			this.set('isWritingMessage', false);
			this.toggleProperty('showMessageActionsModal');
			this.scroller.scrollVertical('.progress-bar');
		},

		async sendReviewRequest() {
			let templates = this.store.peekAll('message-template');
			templates = templates?.length
				? templates
				: await this.store.findAll('message-template');

			const reviewTemplates = templates
				?.toArray()
				?.filter(({ templateType }) => templateType == 'review');

			if (reviewTemplates.length === 1) {
				this.set('newMessage', reviewTemplates[0].content);
				this.set('messageTemplateType', reviewTemplates[0].templateType);
				this.send('sendOwnMessage');
			} else {
				this.set('messageTemplateFilterString', 'review');
				this.send('sendOwnMessage');

				next(() => {
					if (
						document
							.getElementById('modal-add-message-template')
							.classList.contains('hidden')
					) {
						document
							.getElementById('modal-add-message-template')
							.classList.remove('hidden');
					}
				});
			}
		},

		selectedTargetLanguage(value) {
			this.set('theCase.targetLanguage', value.language);
		},
	},
});
