import Component from '@glimmer/component';
import { action } from '@ember/object';
import $ from 'jquery';

export default class MessageAudioRequest extends Component {
	@action
	uploadAudio() {
		// eslint-disable-next-line ember/no-jquery
		$('#dz-uploader-audio').click();
	}
}
